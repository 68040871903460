import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

class FreelanceIndex extends React.Component {
  render() {
    return (
      <Layout location={this.props.location} title={`Projects`}>
        <SEO
          title="Freelance"
          keywords={[`projects`, `resume`, `jmfurlott`, `javascript`, `react`, `freelance`, `consult`, `contract`, `furlott`]}
        />
        <h2>Freelance</h2>
        <p>
        Available for freelance or consultancy opportunities to help build your
        products, big or small!  Some things I can help with include:
        </p>
        <ul>
          <li>React and Javascript frontend web development</li>
          <li>Fullstack Node projects or backends</li>
          <li>Mobile development</li>
          <li>Wordpress sites</li>
          <li>AWS devops and system maintenance</li>
        </ul>
        <p>
          Please contact me at <a
          href="mailto:jmfurlott@gmail.com">jmfurlott@gmail.com</a> with more info
          about your project!
        </p>
      </Layout>
    )
  }
}

export default FreelanceIndex
